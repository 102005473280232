import './progress-bar.scss'

/**
 * Progress Bar
 * 
 * @param {object} props
 * @param {number} props.percentage
 * 
 * @returns {ReactElement}
 */

export default function ProgressBar(props) {
  const {
    percentage = 0,
    className,
  } = props

  return (
      <div 
        className="progress-bar">
        <h4 className="progress-bar__label">
          { percentage } Complete
        </h4>
        <div 
          className="progress-bar__visualization"
          style={{
            width: `${percentage}`
          }}
        >
        </div>
      </div>
  )
}
